<template>
<div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <span class="kt-portlet__head-icon">
                        <SVGIcon
                            name="search"
                            hex-color="#0f88ef"
                        />
                    </span>
                    <h3 class="kt-portlet__head-title">
                        User Search
                    </h3>
                </div>
                <div class="kt-portlet__head-label">
                    <router-link
                        :to="{name: 'CreateLocalUser'}"
                        class="btn btn-outline-primary"
                    >
                        Create Local User
                    </router-link>
                </div>
            </div>

            <div class="kt-portlet__body">
                <div class="form-group row p-2">
                    <div class="col-lg-6">
                        <label>School Name</label>

                        <vue-typeahead-bootstrap
                            :data="schools"
                            :serializer="school => `${school.externalSchoolId}: ${school.schoolName}`"
                            :show-on-focus="true"
                            :max-matches="15"
                            placeholder="Choose a school"
                            @hit="chooseSchool($event)"
                        />
                    </div>

                    <div class="col-lg-6">
                        <label>&nbsp;</label>
                        <label class="kt-checkbox mt-4 pull-right">
                            <input
                                v-model="currentTermOnly"
                                type="checkbox"
                            >
                            <span />
                            Current terms only
                        </label>
                    </div>
                </div>
                <div class="form-group row p-2">
                    <div class="col-lg-6">
                        <label>Last Name</label>
                        <input
                            v-model="lastName"
                            type="text"
                            class="form-control"
                            :disabled="canSearch"
                            @keyup="debouncedSearch"
                            @keyup.enter="search()"
                        >
                    </div>
                    <div class="col-lg-6">
                        <label>First Name</label>
                        <input
                            v-model="firstName"
                            type="text"
                            class="form-control"
                            :disabled="canSearch"
                            @keyup="debouncedSearch"
                            @keyup.enter="search()"
                        >
                    </div>
                </div>
                <div class="form-group row p-2">
                    <div class="col-lg-6">
                        <label>School Email Address</label>
                        <input
                            v-model="userName"
                            type="text"
                            class="form-control"
                            :disabled="canSearch"
                            @keyup="debouncedSearch"
                            @keyup.enter="search()"
                        >
                    </div>
                    <div class="col-lg-6">
                        <label>Google Email Address</label>
                        <input
                            v-model="googleEmail"
                            type="text"
                            class="form-control"
                            :disabled="canSearch"
                            @keyup="debouncedSearch"
                            @keyup.enter="search()"
                        >
                    </div>
                </div>

                <div class="form-group row p-2">
                    <div class="col-6">
                        <button
                            class="btn btn-primary"
                            :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': loading }"
                            @click.stop.prevent="search()"
                        >
                            Search Users
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="kt-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <span class="kt-portlet__head-icon">
                        <SVGIcon name="anecdotal" />
                    </span>
                    <h3 class="kt-portlet__head-title">
                        Search Results
                    </h3>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <td>
                                    <span @click.stop.prevent="columnSort('schoolEmail')">School Email</span>
                                </td>
                                <td>
                                    <span @click.stop.prevent="columnSort('lastName')">Last Name</span>
                                </td>
                                <td>
                                    <span @click.stop.prevent="columnSort('firstName')">First Name</span>
                                </td>
                                <td class="text-nowrap">
                                    <span @click.stop.prevent="columnSort('school')">School</span>
                                </td>
                                <td>
                                    <span @click.stop.prevent="columnSort('schoolTerm')">School Term</span>
                                </td>
                                <td>
                                    <span @click.stop.prevent="columnSort('role')">Role</span>
                                </td>
                                <td>
                                    <span @click.stop.prevent="columnSort('googleEmail')">Google Email</span>
                                </td>
                                <td>
                                    <span @click.stop.prevent="columnSort('twoFA')">2FA</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="user in sortedUsers"
                                :key="`user_${user.userName}_${user.schoolTermId}`"
                                @click.stop.prevent="impersonate(user)"
                            >
                                <td class="text-nowrap">
                                    {{ user.userName }}
                                </td>
                                <td class="text-nowrap">
                                    {{ user.lastName }}
                                </td>
                                <td class="text-nowrap">
                                    {{ user.firstName }}
                                </td>
                                <td class="text-nowrap">
                                    {{ user.schoolName }}
                                </td>
                                <td class="text-nowrap">
                                    {{ user.schoolTermName }}
                                </td>
                                <td class="text-nowrap">
                                    {{ user.roleName }}
                                </td>
                                <td class="text-nowrap">
                                    {{ user.googleEmail }}
                                </td>
                                <td class="no-wrap">
                                    <span v-if="user.twoFactorValidated">
                                        <button
                                            v-b-tooltip.hover.viewport="'Reset 2FA'"
                                            class="btn btn-sm"
                                            @click.stop.prevent="confirm2FAReset(user)"
                                        >
                                            <i class="la la-check-circle kt-font-success" />
                                        </button>
                                    </span>
                                    <span v-else>
                                        <button
                                            v-b-tooltip.hover.viewport="'Not enrolled in 2FA'"
                                            class="btn btn-sm"
                                            @click.stop.prevent=""
                                        >
                                            <i class="la la-times-circle kt-font-danger" />
                                        </button>

                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <b-modal
            id="reset-2fa-confirm-modal"
            ref="reset-2fa-confirm-modal"
            size="m"
            variant="primary"
            static
            centered
            @ok="reset2FA(userToReset2FA)"
        >
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Are you sure?
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="close()"
                />
            </template>
            <template #default>
                <div
                    v-if="userToReset2FA"
                    class="modal-body"
                >
                    <h5>You are resetting 2-Factor Authentication for</h5>
                    <br>
                    <h6>{{ userToReset2FA.lastName }}, {{ userToReset2FA.firstName }}</h6>
                    <h6>{{ userToReset2FA.userName }}</h6>
                </div>
            </template>
        </b-modal>
    </div>
</div>
</template>

<script lang="ts">

import { mapState } from 'vuex';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import _ from 'lodash';
import * as network from '../../network';
import Types from '../../store/Types';

export default {
    name: 'ImpersonateUsers',
    components: {
        VueTypeaheadBootstrap,
    },
    data() {
        return {
            loading: false,
            schoolName: null,
            userName: null,
            googleEmail: null,
            currentTermOnly: true,
            role: '',
            firstName: null,
            lastName: null,
            users: [],
            schoolId: null,
            userToReset2FA: null,
            sortOrder: {
                schoolEmail: 'ascending', lastName: 'ascending', firstName: 'ascending', school: 'ascending', schoolTerm: 'ascending', role: 'ascending', googleEmail: 'ascending', twoFA: 'ascending',
            },
            sortType: 'schoolEmail',
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            anecdotalEvents: (state) => state.database.anecdotalEvents,
            anecdotalEventCategories: (state) => state.database.anecdotalEventCategories,
            schools: (state) => state.database.schools,
        }),
        canSearch() {
            return Boolean(!this.schoolId);
        },
        sortedUsers() {
            const arraySortOrder = (a, b) => {
                let result;
                const type = this.sortType;
                if (a.sort < b.sort) {
                    result = (this.sortOrder[type] === 'ascending') ? -1 : 1;
                }
                if (a.sort > b.sort) {
                    result = (this.sortOrder[type] === 'ascending') ? 1 : -1;
                }
                return result || 0;
            };
            return this.users.map((u) => {
                const user = u;
                switch (this.sortType) {
                case 'schoolEmail':
                    user.sort = u.userName;
                    break;
                case 'lastName':
                    user.sort = u.lastName;
                    break;
                case 'firstName':
                    user.sort = u.firstName;
                    break;
                case 'school':
                    user.sort = u.schoolName;
                    break;
                case 'schoolTerm':
                    user.sort = u.schoolTermName;
                    break;
                case 'role':
                    user.sort = u.roleName;
                    break;
                case 'googleEmail':
                    user.sort = u.googleEmail;
                    break;
                case 'twoFA':
                    user.sort = u.twoFactorEmailValidated ? true : !!u.twoFactorPhoneValidated;
                    break;
                default:
                    user.sort = u.userName;
                    break;
                }
                return user;
            })
                .sort(arraySortOrder);
        },
    },
    mounted() {
        if (this.schools.length == 0) {
            this.$store.dispatch(Types.actions.REFRESH_ADMIN_SCHOOLS);
        }

        const { userId, schoolTermId } = this.$route.query;
        if (userId && schoolTermId) {
            this.$store.dispatch(Types.actions.IMPERSONATE, { userId, schoolTermId });
        }

        this.chooseSchool(this.$store.state.user.school);
    },
    methods: {
        chooseSchool(school) {
            this.schoolId = school.schoolId;
        },
        columnSort(type) {
            this.sortType = type;
            this.sortOrder[type] = (this.sortOrder[type] === 'ascending') ? 'descending' : 'ascending';
            Object.keys(this.sortOrder).forEach((ele) => {
                if (ele !== type) this.sortOrder[ele] = 'init';
            });
        },
        impersonate(user) {
            const { userId, schoolTermId } = user;
            this.$store.dispatch(Types.actions.IMPERSONATE, { userId, schoolTermId });
        },
        search() {
            const v = this;
            const { schoolId } = v;
            if (v.loading) return;
            if (!schoolId) return v.showError('Please choose a school');

            v.loading = true;
            const params = {
                url: {
                    schoolId,
                },
                body: {
                    searchParameters: {
                        userName: v.userName || '',
                        googleEmail: v.googleEmail || '',
                        firstName: v.firstName || '',
                        lastName: v.lastName || '',
                        currentTermOnly: !!v.currentTermOnly,
                    },
                },
            };
            network.users.searchUsers(params, (err, results) => {
                v.loading = false;
                if (err) return v.showError(err);
                v.users = results.users || [];
            });
        },
        debouncedSearch: _.debounce(function () {
            this.search();
        }, 300),
        confirm2FAReset(user) {
            this.userToReset2FA = user;
            this.$refs['reset-2fa-confirm-modal'].show();
        },
        reset2FA(user) {
            const v = this;
            const params = {
                body: {
                    userId: user.userId,
                },
            };
            network.admin.resetTwoFactorAuth(params, (err, results) => {
                v.loading = false;
                this.userToReset2FA = null;
                if (err) return v.showError('Error resetting 2FA');
                v.search();
            });
        },
    },
};
</script>

<style src="../../css/colorSwatch.css" />

<style scoped>
.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: none;
    border-bottom: 1px solid #ebedf2;
}

thead span {
    cursor: pointer;
}

tbody tr td {
    cursor: pointer;
}

td.td-col-1 {
    width: 72px;
}

td.td-col-2 {
    width: 600px;
}

td.td-col-3 {
    width: 140px;
}

.kt-widget__media {
    margin-left: 40px;
}

.kt-media-group .kt-media {
    margin-left: -22px;
}

.kt-media-group .kt-media:first-child {
    margin-left: 40px;
}
</style>
