var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "kt-portlet" }, [
          _c("div", { staticClass: "kt-portlet__head" }, [
            _c("div", { staticClass: "kt-portlet__head-label" }, [
              _c(
                "span",
                { staticClass: "kt-portlet__head-icon" },
                [
                  _c("SVGIcon", {
                    attrs: { name: "search", "hex-color": "#0f88ef" },
                  }),
                ],
                1
              ),
              _c("h3", { staticClass: "kt-portlet__head-title" }, [
                _vm._v(" User Search "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "kt-portlet__head-label" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-outline-primary",
                    attrs: { to: { name: "CreateLocalUser" } },
                  },
                  [_vm._v(" Create Local User ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c("div", { staticClass: "form-group row p-2" }, [
              _c(
                "div",
                { staticClass: "col-lg-6" },
                [
                  _c("label", [_vm._v("School Name")]),
                  _c("vue-typeahead-bootstrap", {
                    attrs: {
                      data: _vm.schools,
                      serializer: (school) =>
                        `${school.externalSchoolId}: ${school.schoolName}`,
                      "show-on-focus": true,
                      "max-matches": 15,
                      placeholder: "Choose a school",
                    },
                    on: {
                      hit: function ($event) {
                        return _vm.chooseSchool($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-lg-6" }, [
                _c("label", [_vm._v(" ")]),
                _c("label", { staticClass: "kt-checkbox mt-4 pull-right" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentTermOnly,
                        expression: "currentTermOnly",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.currentTermOnly)
                        ? _vm._i(_vm.currentTermOnly, null) > -1
                        : _vm.currentTermOnly,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.currentTermOnly,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.currentTermOnly = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.currentTermOnly = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.currentTermOnly = $$c
                        }
                      },
                    },
                  }),
                  _c("span"),
                  _vm._v(" Current terms only "),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "form-group row p-2" }, [
              _c("div", { staticClass: "col-lg-6" }, [
                _c("label", [_vm._v("Last Name")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.lastName,
                      expression: "lastName",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: _vm.canSearch },
                  domProps: { value: _vm.lastName },
                  on: {
                    keyup: [
                      _vm.debouncedSearch,
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search()
                      },
                    ],
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.lastName = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "col-lg-6" }, [
                _c("label", [_vm._v("First Name")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.firstName,
                      expression: "firstName",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: _vm.canSearch },
                  domProps: { value: _vm.firstName },
                  on: {
                    keyup: [
                      _vm.debouncedSearch,
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search()
                      },
                    ],
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.firstName = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "form-group row p-2" }, [
              _c("div", { staticClass: "col-lg-6" }, [
                _c("label", [_vm._v("School Email Address")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.userName,
                      expression: "userName",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: _vm.canSearch },
                  domProps: { value: _vm.userName },
                  on: {
                    keyup: [
                      _vm.debouncedSearch,
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search()
                      },
                    ],
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.userName = $event.target.value
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "col-lg-6" }, [
                _c("label", [_vm._v("Google Email Address")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.googleEmail,
                      expression: "googleEmail",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", disabled: _vm.canSearch },
                  domProps: { value: _vm.googleEmail },
                  on: {
                    keyup: [
                      _vm.debouncedSearch,
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search()
                      },
                    ],
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.googleEmail = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "form-group row p-2" }, [
              _c("div", { staticClass: "col-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    class: {
                      "kt-spinner kt-spinner--sm kt-spinner--light":
                        _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.search()
                      },
                    },
                  },
                  [_vm._v(" Search Users ")]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "kt-portlet" }, [
          _c("div", { staticClass: "kt-portlet__head" }, [
            _c("div", { staticClass: "kt-portlet__head-label" }, [
              _c(
                "span",
                { staticClass: "kt-portlet__head-icon" },
                [_c("SVGIcon", { attrs: { name: "anecdotal" } })],
                1
              ),
              _c("h3", { staticClass: "kt-portlet__head-title" }, [
                _vm._v(" Search Results "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-hover" }, [
                _c("thead", [
                  _c("tr", [
                    _c("td", [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.columnSort("schoolEmail")
                            },
                          },
                        },
                        [_vm._v("School Email")]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.columnSort("lastName")
                            },
                          },
                        },
                        [_vm._v("Last Name")]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.columnSort("firstName")
                            },
                          },
                        },
                        [_vm._v("First Name")]
                      ),
                    ]),
                    _c("td", { staticClass: "text-nowrap" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.columnSort("school")
                            },
                          },
                        },
                        [_vm._v("School")]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.columnSort("schoolTerm")
                            },
                          },
                        },
                        [_vm._v("School Term")]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.columnSort("role")
                            },
                          },
                        },
                        [_vm._v("Role")]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.columnSort("googleEmail")
                            },
                          },
                        },
                        [_vm._v("Google Email")]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.columnSort("twoFA")
                            },
                          },
                        },
                        [_vm._v("2FA")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.sortedUsers, function (user) {
                    return _c(
                      "tr",
                      {
                        key: `user_${user.userName}_${user.schoolTermId}`,
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.impersonate(user)
                          },
                        },
                      },
                      [
                        _c("td", { staticClass: "text-nowrap" }, [
                          _vm._v(" " + _vm._s(user.userName) + " "),
                        ]),
                        _c("td", { staticClass: "text-nowrap" }, [
                          _vm._v(" " + _vm._s(user.lastName) + " "),
                        ]),
                        _c("td", { staticClass: "text-nowrap" }, [
                          _vm._v(" " + _vm._s(user.firstName) + " "),
                        ]),
                        _c("td", { staticClass: "text-nowrap" }, [
                          _vm._v(" " + _vm._s(user.schoolName) + " "),
                        ]),
                        _c("td", { staticClass: "text-nowrap" }, [
                          _vm._v(" " + _vm._s(user.schoolTermName) + " "),
                        ]),
                        _c("td", { staticClass: "text-nowrap" }, [
                          _vm._v(" " + _vm._s(user.roleName) + " "),
                        ]),
                        _c("td", { staticClass: "text-nowrap" }, [
                          _vm._v(" " + _vm._s(user.googleEmail) + " "),
                        ]),
                        _c("td", { staticClass: "no-wrap" }, [
                          user.twoFactorValidated
                            ? _c("span", [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.viewport",
                                        value: "Reset 2FA",
                                        expression: "'Reset 2FA'",
                                        modifiers: {
                                          hover: true,
                                          viewport: true,
                                        },
                                      },
                                    ],
                                    staticClass: "btn btn-sm",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.confirm2FAReset(user)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "la la-check-circle kt-font-success",
                                    }),
                                  ]
                                ),
                              ])
                            : _c("span", [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.viewport",
                                        value: "Not enrolled in 2FA",
                                        expression: "'Not enrolled in 2FA'",
                                        modifiers: {
                                          hover: true,
                                          viewport: true,
                                        },
                                      },
                                    ],
                                    staticClass: "btn btn-sm",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "la la-times-circle kt-font-danger",
                                    }),
                                  ]
                                ),
                              ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
        _c("b-modal", {
          ref: "reset-2fa-confirm-modal",
          attrs: {
            id: "reset-2fa-confirm-modal",
            size: "m",
            variant: "primary",
            static: "",
            centered: "",
          },
          on: {
            ok: function ($event) {
              return _vm.reset2FA(_vm.userToReset2FA)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ close }) {
                return [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(" Are you sure? "),
                  ]),
                  _c("button", {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close",
                    },
                    on: {
                      click: function ($event) {
                        return close()
                      },
                    },
                  }),
                ]
              },
            },
            {
              key: "default",
              fn: function () {
                return [
                  _vm.userToReset2FA
                    ? _c("div", { staticClass: "modal-body" }, [
                        _c("h5", [
                          _vm._v(
                            "You are resetting 2-Factor Authentication for"
                          ),
                        ]),
                        _c("br"),
                        _c("h6", [
                          _vm._v(
                            _vm._s(_vm.userToReset2FA.lastName) +
                              ", " +
                              _vm._s(_vm.userToReset2FA.firstName)
                          ),
                        ]),
                        _c("h6", [_vm._v(_vm._s(_vm.userToReset2FA.userName))]),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }